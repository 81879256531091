<template>
  <a-dropdown placement="bottomRight">
    <div class="header" style="background: #00dddd !important; width: 45px; text-align: center">
      <a-icon type="align-center" style="color: #fff; font-size: 24px" />
    </div>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <a-row class="row">
          <a-col class="col" v-for="item in list" :key="item.id">
            <div @click="onJump(item)" class="col-item">
              <div
                style="height: 40px; width: 40px; margin: 0 auto; border-radius: 10px; line-height: 40px"
                
              >
              <img :src="`/icon/${item.functionId}.png`" alt="icon" style="width: 100%; height: 100%;" />
              </div>
              <div style="line-height: 30px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
                {{ item.functionName }}
              </div>
            </div>
          </a-col>
        </a-row>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { getFunctionListUsingPOST1 } from '@/api/api/shouye.ts'
export default {
  name: 'AvatarDropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    getFunctionListUsingPOST1()
    .then((res) => {
      this.list = res?.data?.list
        ?.filter((item) => item.isShow == '1') 
        .sort((a, b) => a.functionId - b.functionId)
    })
    .catch((error) => {
      this.$message.error(((error.response || {}).data || {}).msg || '请求出现错误')
    })
  },
  data() {
    return {
      list: [],
    }
  },
  methods: {
    handleToCenter() {
      this.$router.push({ path: '/my/info' })
    },
    handleToSettings() {
      this.$router.push({ path: '/account/settings' })
    },
    onJump(item) {
      window.location.href = item.link
    },
  },
}
</script>

<style lang="less" scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 500px;
  max-width: 760px;
  padding: 10px;
  padding-top: 20px;
  .col {
    width: 90px;
    .col-item {
      margin-bottom: 10px;
      text-align: center;
      cursor: pointer;
    }
  }
}
.header {
  // background-color: #a34335;
  color: #fff;
}
.header:hover {
  // background-color: #a34335;
}
// .ant-pro-drop-down {
//   :deep(.action) {
//     margin-right: 8px;
//   }
//   :deep(.ant-dropdown-menu-item) {
//     min-width: 160px;
//   }
// }
</style>
