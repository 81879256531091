import request from '@/utils/request'
//获取wx设置
export function getWechatSetConfig() {
  return request({
    url: `/fds/wechatSet/getWechatSetConfig`,
    method: 'get',
  })
}

//获取wx登录跳转地址
export function getWechat(params) {
  return request({
    url: `/fds/oauth/wechat`,
    method: 'get',
    params: params,
  })
}
//微信登录回调
export function updateWechatCallback(code, state) {
  return request({
    url: `/fds/oauth/callback?code=${code}&state=${state}`,
    method: 'get',
  })
}
